import React from 'react'

import styles from './SaveMeduza.module.css'

interface SaveMeduzaProps {
  lang: 'ru' | 'en'
}

export const SaveMeduza: React.FC<SaveMeduzaProps> = ({ lang }) => {
  if (lang === 'ru') {
    return (
      <div className={styles.button}>
        <a
          className={styles.ru}
          href="https://mdza.io/WGX5u8vQA70"
          target="_blank"
          rel="noreferrer"
        >
          <span>10 лет с «Медузой». И хочу еще!</span>
        </a>
      </div>
    )
  }
  return (
    <div className={styles.root}>
      <a href="https://mdza.io/KbA_9es33Mg" target="_blank" rel="noreferrer">
        <span>I want to support Meduza</span>
      </a>
      <div className={styles.overlay} />
    </div>
  )
}
